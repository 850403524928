<template>
  <div class="box">
    <div class="add">
      <el-button type="primary" @click="added">新增分类</el-button>
      <el-button type="primary" @click="all">批量删除</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 380px)"
      @selection-change="handleSelectionChange"
      border
      :header-cell-style="{
        'background-color': '#f2f2f2',
        'text-align': 'center',
        border: '1px solid #ddd',
      }"
    >
      <el-table-column type="selection" width="50"> </el-table-column>

      <el-table-column prop="orderSq" label="排序" align="center">
      </el-table-column>
      <el-table-column prop="areaImg" label="商品品类图像" align="center">
        <template #default="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.areaImg"
            fit="scale-down"
          />
        </template>
      </el-table-column>
      <el-table-column prop="classifyName" label="品类名称" align="center">
      </el-table-column>
      <el-table-column prop="creatTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop=" " label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
          <template>
            <el-popconfirm
              title="确定删除吗？"
              @onConfirm="handleDelte(scope.row.id)"
              @confirm="handleDelte(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
          <el-button
            type="text"
            v-if="scope.row.isShelves == 1"
            @click="upper(scope.row)"
            >下架</el-button
          >
          <el-button
            type="text"
            v-else
            style="color: red"
            @click="upper(scope.row)"
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="添加积分商品分类"
      :visible.sync="showDialog"
      width="800px"
      height="600px"
    >
      <div class="dia">
        <el-form label-width="120px" label-height="60px">
          <el-row>
            <el-col :span="23">
              <el-form-item label="分类名称：" prop="contactName">
                <el-input
                  v-model="from.classifyName"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传图像：" prop="contactName">
                <el-upload
                  class="avatar-uploader"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                >
                  <img
                    v-if="from.areaImg"
                    :src="from.areaImg"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="margin-left: 60px; color: red">排序数越大越靠前</div>
        <div class="diafour">
          <el-button size="medium" type="danger" @click="showDialog = false"
            >取消</el-button
          >
          <el-button size="medium" type="primary" @click="save"
            >保存
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [{}],
      queryInfo: {
        condition: {
          commodityType: 3,
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 30,
      },
      from: {
        classifyImg: "",
        areaImg:""
      },
      total: 0,
      showDialog: false,
      fileList: [],
      // uploadURL: "http://121.37.131.119:9003/api/upload/uploadFile",
      disabled: false,
      imageUrl: "",
      xzlist: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleDelte(id) {
      console.log("99");
      var that = this;
      this.$http
        .post("/commodityType/delete", {
          id: id,
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.$message.success("删除成功");

            that.getList();
          }
        });
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/commodityType/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    added() {
      this.showDialog = true;
    },
    handleSelectionChange(val) {
      this.xzlist = val;
    },
    all() {
      var that = this;
      this.$http
        .post("/commodityType/deleteBatch", this.xzlist)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.$message.success("成功");

            that.getList();
          }
        });
    },
    handleAvatarSuccess(res, file) {
      console.log(file.response.data.url, "88888888");
      this.from.areaImg = file.response.data.url;
    },
    save() {
      this.from.commodityType = 3;
      this.from.classifyResource = 2;
      this.$http
        .post("/commodityType/saveCommodityType", this.from)
        .then((response) => {
          console.log(response.data.data);
          if (response.data.code == 200) {
            this.$message.success("成功");
            this.showDialog = false;
            this.from = {};
            this.getList();
          }
        });
    },
    edit(id) {
      var that = this;
      that.from.id = id;
      that.showDialog = true;
      that.$http
        .post("/commodityType/queryOne", {
          id: id,
        })
        .then(function (response) {
          console.log(response.data.data.data[0], that.from , 222);
          that.from.orderSq = response.data.data.data[0].orderSq;
          that.from.categoryName = response.data.data.data[0].categoryName;
          that.from.areaImg = response.data.data.data[0].areaImg;
        });
    },
    upper(row) {
      var that = this;
      this.$http
        .post("/commodityType/updateStatus", {
          id: row.id,
          isShelves: row.isShelves,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.getList();
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
