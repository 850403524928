import { render, staticRenderFns } from "./integraltype.vue?vue&type=template&id=22c29c96&scoped=true"
import script from "./integraltype.vue?vue&type=script&lang=js"
export * from "./integraltype.vue?vue&type=script&lang=js"
import style0 from "./integraltype.vue?vue&type=style&index=0&id=22c29c96&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22c29c96",
  null
  
)

component.options.__file = "integraltype.vue"
export default component.exports